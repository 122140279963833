import App from './App.vue';
import axios from 'axios';
import keycloak from './security/keycloak-config';
import router from './router';
import {createApp, toRaw} from 'vue';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'vue3-easy-data-table/dist/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';

// Import Sweetalert2 CSS
import 'sweetalert2/src/sweetalert2.scss'

// Custom styles
import './main.scss'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";
// Translations provided by Vuetify
import { es } from 'vuetify/locale'
import {createPinia} from "pinia";
import {createHead, useHead} from '@unhead/vue';
import {useConfigurationStore} from "@/store/Enroller.store";

const backend_url = window.location.host.replace('8080','3000');
const backend_url_parts = backend_url.split('.');
backend_url_parts.splice(-2, 0, "api");
axios.defaults.baseURL = `${window.location.protocol}//${backend_url_parts.join('.')}`;

const app = createApp(App);
const store = createPinia();
const head = createHead();

const initialStateVuetify = {
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
            fa,
        },
    },
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {},
            }
        }
    },
    locale: {
        locale: 'es',
        fallback: 'ES',
        messages: { es },
    },
    components,
    directives,
};

const initialStateHead = {
    title: 'RedPay',
    titleTemplate: '%s %separator %siteName',
    // Template params
    templateParams: { separator: '|', siteName: 'Cobrar usando RedPay' },
    link: [{
        href:'',
        rel: 'icon',
    }],
};

keycloak
    .init({
        onLoad:'check-sso',
        redirectUri: `${window.location.origin}${window.location.pathname}`
    })
    .then(async () => {
        app.use(store);
        const chargeConfiguration = useConfigurationStore();
        await chargeConfiguration.getEnrollerConfiguration();

        /***********************************************************
            CARGA DE CONFIGURACIÓN DE COLORES DE LA PLATAFORMA
         ************************************************************/
        initialStateVuetify.theme.themes.light.colors =  {
            ...JSON.parse(toRaw(chargeConfiguration.enroller).branding_color)
        };

        const vuetify = createVuetify(initialStateVuetify);
        initialStateHead.title = chargeConfiguration.enroller.name;

        initialStateHead.link = [
            {
                href: `${chargeConfiguration.enroller.assets_url}favicon.ico`,
                rel: 'icon',
            }
        ]
        useHead(initialStateHead);
        app.use(head);
        app.use(vuetify);
        app.use(router);
        app.use(BootstrapIconsPlugin);
        app.mount('#app');
    }).catch((e) => {
    console.log('Serwer lezy: ' + JSON.stringify(e));
});
