import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import {keycloakGuard} from "@/services/KeyCloak.service";
import {EnrollerModule, UserRol} from "@/common/Utils";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: {path: "/orders"},
    },
    {
        path: "/collect/order",
        name: EnrollerModule.GATEWAY_LINK_PAGO,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.GATEWAY_LINK_PAGO,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/CollectWithBlixView.vue"),
    },
    {
        path: "/collect/subscriptions",
        name: EnrollerModule.ORDEN_SUSCRIPCION,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.ORDEN_SUSCRIPCION,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/CollectSubscriptionWithBlixView.vue"),
    },
    {
        path: "/subscription",
        name: EnrollerModule.LISTA_SUSCRIPCION,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.LISTA_SUSCRIPCION,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/SubscriptionListView.vue"),
    },
    {
        path: "/collect/aliaspay",
        name: EnrollerModule.GATEWAY_ALIASPAY,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.GATEWAY_ALIASPAY,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/CollectAliasPayWithBlixView.vue"),
    },
    {
        path: "/subscription/:id",
        name: "subscription_detail",
        meta: {
            isPublic: false,
            module_code: EnrollerModule.LISTA_SUSCRIPCION,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/SubscriptionDetailView.vue"),
    },
    {
        path: "/collect/jumpseller",
        name: EnrollerModule.GATEWAY_JUMPSELLER,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.GATEWAY_JUMPSELLER,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/CollectWithJumpsellerView.vue"),
    },
    {
        path: "/collect/wordpress",
        name: EnrollerModule.GATEWAY_WOOCOMMERCE,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.GATEWAY_WOOCOMMERCE,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/CollectWithWordpressView.vue"),
    },
    {
        path: "/collect/vtex",
        name: EnrollerModule.GATEWAY_VTEX,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.GATEWAY_VTEX,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/CollectWithVtexView.vue"),
    },
    {
        path: "/collect/externalapp",
        name: EnrollerModule.GATEWAY_PERSONALIZADO,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.GATEWAY_PERSONALIZADO,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/CollectWithExternalApplicationView.vue"),
    },
    {
        path: "/collect/:token_type/:uuid",
        name: "collect_embedded",
        meta: {
            isPublic: false,
            module_code: EnrollerModule.GATEWAY_LINK_PAGO,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/CollectEmbeddedView.vue"),
    },
    {
        path: "/collect/finish/:status",
        name: "collect_finish",
        meta: {isPublic: true},
        component: () => import("../views/CollectFinishView.vue"),
    },
    {
        path: "/order/checkout/:token_type/:token_uuid",
        name: "order_checkout",
        meta: {isPublic: true},
        component: () => import("../views/OrderCheckoutView.vue"),
    },
    {
        path: "/order/:id",
        name: "orders_detail",
        meta: {
            isPublic: false,
            module_code: EnrollerModule.ORDEN_COMPRA,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/OrderDetailView.vue"),
    },
    {
        path: "/orders",
        name: EnrollerModule.ORDEN_COMPRA,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.ORDEN_COMPRA,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/OrderListView.vue"),
    },
    {
        path: "/orders/dashboard",
        name: EnrollerModule.ORDEN_COMPRA_DASHBOARD,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.ORDEN_COMPRA_DASHBOARD,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/OrderDashboardListView.vue"),
    },
    {
        path: "/onboarding",
        name: EnrollerModule.ONBOARDING,
        meta: {isPublic: true},
        component: () => import("@/views/Onboarding/OnboardingFirstStep/OnBoardingFirstFormView.vue"),
    },
    {
        path: "/onboarding/confirm",
        name: "onboarding_confirm",
        meta: {
            isPublic: false,
            module_code: EnrollerModule.ONBOARDING,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () =>
            import("@/views/Onboarding/OnBoardingSecondStep/OnBoardingSecondFormView.vue"),
    },
    {
        path: "/configuration/commerce",
        name: EnrollerModule.GESTION_COMERCIOS,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.GESTION_COMERCIOS,
            user_role: [UserRol.ADMIN]
        },
        component: () => import("../views/CommerceConfiguration.vue"),
    },
    {
        path: "/configuration/branch",
        name: EnrollerModule.GESTION_PUNTOS_VENTA,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.GESTION_PUNTOS_VENTA,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/ConfigurationCommerceBranch.vue"),
    },
    {
        path: "/configuration/apikey",
        name: EnrollerModule.GESTION_API_KEY,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.GESTION_API_KEY,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/ConfigurationApiKey.vue"),
    },
    {
        path: "/error/",
        name: "error",
        meta: {isPublic: true},
        component: () => import("../views/OrderErrorView.vue"),
    },
    {
        path: "/error/redpay/:code",
        name: "redpay_error",
        meta: {isPublic: true},
        component: () => import("../views/OrderErrorRedPayView.vue"),
    },
    {
        path: "/detail/:uuid",
        name: "detail_list",
        meta: {
            isPublic: false,
            module_code: EnrollerModule.ORDEN_COMPRA_DASHBOARD,
            user_role: [UserRol.COMERCIO, UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/ReverseDetailView.vue"),
    },
    {
        path: "/dashboard/commerces",
        name: EnrollerModule.CUENTAS_COMERCIOS,
        meta: {
            isPublic: false,
            module_code: EnrollerModule.CUENTAS_COMERCIOS,
            user_role: [UserRol.COMERCIAL, UserRol.ADMIN]
        },
        component: () => import("../views/CommerceListInfo.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "errorView",
        meta: {isPublic: true},
        component: () => import("../views/OrderErrorView.vue"),
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// We apply the keycloak guard to all routes
router.beforeEach(keycloakGuard);


export default router;
