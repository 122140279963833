import keycloak from "@/security/keycloak-config";
import {NavigationGuardWithThis} from "vue-router";
import {useConfigurationStore} from "@/store/Enroller.store";


export const keycloakGuard: NavigationGuardWithThis<undefined> = async (
    to,
    from,
    next
) => {
  const store = useConfigurationStore();
  await store.getEnrollerConfiguration();


  // If route is public, you can pass
  if (to.meta.isPublic) {
    return next();
  }

  // Else, if you are not authenticated, ask for login
  if (!keycloak.authenticated) {
    return keycloak.login();
  }

  // If you are not a valid module, you are not allowed to pass
  if(!store.enrollerModules.includes(String(to.meta.module_code))){
    history.back();
  }

  // If you are not a valid user role, you are not allowed to pass
  if(!String(to.meta.user_role).split(',').some((role)=> keycloak.tokenParsed?.roles?.includes(role))){
    history.back();
  }

  // Else, if your token is not expired, you can pass
  if (!keycloak.isTokenExpired()) {
    return next();
  }

  // Else, we try to refresh the token
  await keycloak
      .updateToken(Number(process.env.VUE_APP_APP_INACTIVITY_TIME))
      .then(next) // If success, you can pass
      .catch(keycloak.logout); // If we could not refresh the user's token, logout
}
